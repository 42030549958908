import ISocialRESTClient from 'rest-clients/ISocialRESTClient';
import { RESTClient } from './RESTClient';
import { inject, injectable } from 'inversify';
import type { IAuthService } from 'services/IAuthService';
import Nullable from 'models/Nullable';
import axios, { AxiosError } from 'axios';

@injectable()
export default class SocialRESTClient
    extends RESTClient
    implements ISocialRESTClient
{
    @inject('IAuthService')
    private readonly authService!: IAuthService;

    constructor() {
        super();
        this.setBaseURL(
            'https://wod-social-868075656275.us-central1.run.app/api/v1/'
        );
    }

    async getUserTag(iss: string): Promise<Nullable<string>> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const socialToken = await this.getToken(token);

        if (!socialToken) return null;

        const url = this.getFullURL(`user-tag/${iss}`);

        try {
            const response = await axios.get(url, this.getHeader(socialToken));
            return response.data;
        } catch {
            return null;
        }
    }
    deleteUserTag(iss: string): Promise<boolean> {
        throw new Error('Method not implemented.');
    }
    async createTag(iss: string, tag: string): Promise<Nullable<string>> {
        const token = await this.authService.getToken();
        if (token === null) return 'Unexpected error!';

        const socialToken = await this.getToken(token);

        if (!socialToken) return 'Unexpected error!';

        const url = this.getFullURL(`user-tag/user/${iss}/tag/${tag}`);

        try {
            await axios.post(url,  undefined,this.getHeader(socialToken));
            return null;
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response) {
                // Handle server responses with error status codes
                switch (axiosError.response.status) {
                    case 400:
                        console.log(axiosError);
                        return (
                            axiosError.response.data ||
                            'Invalid request.'
                        );
                    case 401:
                        return 'Unauthorized access.';
                    case 499:
                        return 'Request was canceled.';
                    default:
                        return `Server error: ${axiosError.response.status}`;
                }
            } else if (axiosError.request) {
                return 'No response received from the server.';
            } else {
                return 'An error occurred while setting up the request.';
            }
        }
    }
    async updateTag(iss: string, tag: string): Promise<Nullable<string>> {
        const token = await this.authService.getToken();
        if (token === null) return 'Unexpected error!';

        const socialToken = await this.getToken(token);

        if (!socialToken) return 'Unexpected error!';

        const url = this.getFullURL(`user-tag/user/${iss}/tag/${tag}`);

        try {
            await axios.put(url, undefined, this.getHeader(socialToken));
            return null;
        } catch (error) {
            const axiosError = error as AxiosError;

            if (axiosError.response) {
                // Handle server responses with error status codes
                switch (axiosError.response.status) {
                    case 400:
                        return (
                            axiosError.response.data ||
                            'Invalid request.'
                        );
                    case 401:
                        return 'Unauthorized access.';
                    case 499:
                        return 'Request was canceled.';
                    default:
                        return `Server error: ${axiosError.response.status}`;
                }
            } else if (axiosError.request) {
                return 'No response received from the server.';
            } else {
                return 'An error occurred while setting up the request.';
            }
        }
    }
}
