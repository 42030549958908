import Reward from "./Reward";

export default interface Pledge{
    pledge_id : number;
    wod_email : string;
    backer_email : string;
    reward_id : number;
    quantity : number;
    claimed_quantity : number;
    available_quantity : number;
    reward : Reward;
    backing_platform : EPledgeBackingPlatform;
}


export enum EPledgeBackingPlatform{
    BACKERKIT = 1,
    SHOPIFY = 2
}