import { Container } from 'inversify';
import { AuthRESTClient } from '../rest-clients/Implementations/AuthRESTClient';
import { AuthGateRESTClient } from '../rest-clients/Implementations/AuthGateRESTClient';
import TransRESTClient from '../rest-clients/Implementations/TransRESTClient';
import DoDRESTClient from '../rest-clients/Implementations/DoDRESTClient';
import CookieService from '../services/Implementations/CookieService';
import ExtraService from '../services/Implementations/ExtraService';
import UIService from '../services/Implementations/UIService';
import { WindowMessage } from '../services/Implementations/WindowMessage';
import { UserValidator } from '../services/Implementations/UserValidator';
import { LocalStorageService } from '../services/Implementations/LocalStorageService';
import { SessionStorageService } from '../services/Implementations/SessionStorageService';
import TransService from '../services/Implementations/TransService';
import { AuthService } from '../services/Implementations/AuthService';
import NavigationDataService from '../services/Implementations/NavigationDataService';
import AuthManagementRESTClient from '../rest-clients/Implementations/AuthManagementRESTClient';
import TreasuryService from '../services/Implementations/TreasuryService';
import TreasuryRESTClient from '../rest-clients/Implementations/TreasuryRESTClient';
import SelectedPledgeItemsService from '../services/Implementations/SelectedPledgeItemsService';
import ShopifyAddToCartService from '../services/Implementations/ShopifyAddToCartservice';
import DiscountService from '../services/Implementations/DiscountService';
import TextureRegistry from 'services/Implementations/TextureRegistry';
import SocialService from 'services/Implementations/SociaService';
import SocialRESTClient from 'rest-clients/Implementations/SocialRESTClient';

export function installRESTClients(container: Container) {
    container.bind('IAuthRESTClient').to(AuthRESTClient).inSingletonScope();
    container
        .bind('IAuthGateRESTClient')
        .to(AuthGateRESTClient)
        .inSingletonScope();
    container.bind('ITransRESTClient').to(TransRESTClient).inSingletonScope();
    container.bind('IDoDRESTClient').to(DoDRESTClient).inSingletonScope();
    container.bind('IAuthManagementRESTClient').to(AuthManagementRESTClient).inSingletonScope();
    container.bind('ITreasuryRESTClient').to(TreasuryRESTClient).inSingletonScope();
    container.bind('IDoDRESTClient').to(DoDRESTClient).inSingletonScope();
    container.bind('ISocialRESTClient').to(SocialRESTClient).inSingletonScope();
}

export function installServices(container: Container) {
    container.bind('IAuthService').to(AuthService).inSingletonScope();
    container.bind('ITransService').to(TransService).inSingletonScope();
    container.bind('ISocialService').to(SocialService).inSingletonScope();
    container
        .bind('ISessionStorageService')
        .to(SessionStorageService)
        .inSingletonScope();
    container
        .bind('ILocalStorageService')
        .to(LocalStorageService)
        .inSingletonScope();
    container.bind('IUserValidator').to(UserValidator).inSingletonScope();
    container.bind('IWindowMessage').to(WindowMessage).inSingletonScope();
    container.bind('IUIService').to(UIService).inSingletonScope();
    container.bind('IExtraService').to(ExtraService).inSingletonScope();
    container.bind('ITextureRegistry').to(TextureRegistry).inSingletonScope();
    container.bind('ITreasuryService').to(TreasuryService).inSingletonScope();
    container.bind('ICookieService').to(CookieService).inSingletonScope();
    container.bind('INavigationDataService').to(NavigationDataService).inSingletonScope();
    container.bind('ISelectedPledgeItemsService').to(SelectedPledgeItemsService).inSingletonScope();
    container.bind('IShopifyAddToCartService').to(ShopifyAddToCartService).inSingletonScope();
    container.bind('IDiscountService').to(DiscountService).inSingletonScope();
    
}
