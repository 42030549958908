import { inject, injectable } from 'inversify';
import Nullable from 'models/Nullable';
import type ISocialRESTClient from 'rest-clients/ISocialRESTClient';
import ISocialService from 'services/ISocialService';

@injectable()
export default class SocialService implements ISocialService {
    @inject('ISocialRESTClient')
    private readonly socialRESTClient!: ISocialRESTClient;

    getUserTag(iss: string): Promise<Nullable<string>> {
        return this.socialRESTClient.getUserTag(iss);
    }
    deleteUserTag(iss: string): Promise<boolean> {
        return this.socialRESTClient.deleteUserTag(iss);
    }
    creatUserTag(iss: string, tag: string): Promise<Nullable<string>>{
        return this.socialRESTClient.createTag(iss, tag);
    }
    updateUserTag(iss: string, tag: string): Promise<Nullable<string>> {
        return this.socialRESTClient.updateTag(iss, tag);
    }

    formatForDisplay(tag:string) : Nullable<string> {
        const formatForEdit = this.formatForEdit(tag);
        if(formatForEdit.length === tag.length){
            return null;
        }
        return `${this.capitalizeFirstLetter(formatForEdit)}`
    }

    capitalizeFirstLetter(str:string) : string {
        if (!str) return str;
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    formatForEdit(tag:string) : string{
        const indexOfColon = tag.indexOf(':');
        if(indexOfColon<0){
            return tag;
        }
        return tag.substring(0, indexOfColon)
    }

    validateTag(tag: string): Nullable<string> {
        if (tag.length <= 3) {
            return 'Tag Name too short!';
        }
        if (tag.length > 15) {
            return 'Tag Name too long!';
        }

        const isLetter = (currentChar : string)=>{
            return currentChar.toLowerCase() !== currentChar.toUpperCase();
        }

        let hasLetter = false;
        for (let i = 0; i < tag.length; i++) {
            let currentChar = tag.charAt(i);
            if (isLetter(currentChar)) {
                hasLetter = true;
                continue;
            }
            if (currentChar >= '0' && currentChar <= '9') {
                continue;
            }
            return 'Tag Name must only contain letters and numbers!';
        }
        if (!hasLetter) {
            return 'Tag Name must contain at least one letter!';
        }

        return null;
    }
}
