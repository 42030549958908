import styles from './customer-account.module.scss';
import modalStyles from '../modal/customer-modal.module.scss';
import CustomerModal from '../modal/customer-modal.component';
import { useInjection } from 'inversify-react';
import { IAuthService } from '../../../services/IAuthService';
import { useEffect, useRef, useState } from 'react';
import IUIService from '../../../services/IUIService';
import { IAuthGateRESTClient } from '../../../rest-clients/IAuthGateRESTClient';
import classes from '../../../utils/classes';
import DownloadLauncherButton from '../../download-launcher-button/download-launcher-button.component';
import { useSearchParams } from 'react-router-dom';
import AccountLinking from './account-linking/account-linking.component';
import AdminOnly from '../../admin-only/AdminOnly.component';
import data from '../../../data/deployTime.json';
import LogOut from '../../../components/log-out/log-out.component';
import ISocialService from 'services/ISocialService';
import Nullable from 'models/Nullable';

export default function CustomerAccount() {
    const authService = useInjection<IAuthService>('IAuthService');
    const uiService = useInjection<IUIService>('IUIService');
    const authGateRESTClient = useInjection<IAuthGateRESTClient>(
        'IAuthGateRESTClient'
    );
    const socialService = useInjection<ISocialService>('ISocialService');

    const [searchParams] = useSearchParams();

    const from = searchParams.get('from');

    const [iss, setISS] = useState('example@example.com');
    const [userTag, setUserTag] = useState<Nullable<string>>(null);

    useEffect(() => {
        authService.getISS().then((x) => {
            if (!!x) {
                setISS(x);
                getUserTag(x);
            }
        });
    }, []);

    const getUserTag = async (iss: string) => {
        uiService.showLoading();
        const tag = await socialService.getUserTag(iss);
        setUserTag(tag);
        uiService.hideLoading();
    };

    async function changePasswordClicked() {
        uiService.showLoading();
        const message = await authGateRESTClient.reset(iss, null);
        uiService.hideLoading();

        if (message === null) {
            uiService.showSuccessNotification(
                'Reset instructions sent to your email!'
            );
        } else {
            uiService.showErrorNotification(message.value);
        }
    }

    const changeEmail = async (email: string)=>{
        uiService.showLoading();
        const message = await authService.requestChangeEmail(email);

        if (message === null) {
            uiService.showSuccessNotification(
                'Request for email change submitted successfuly. Please check your email!'
            );
        } else {
            uiService.showErrorNotification(message.value);
        }

        uiService.hideLoading();
    }

   

    const passwordPanel = (
        <div className={classes(modalStyles.row, styles.passwordRow)}>
            <div className={modalStyles.label}>Password</div>
            <div className={modalStyles.content}>
                <div className={styles.password}>***************</div>
                <div className={classes(modalStyles.last, styles.pswButton)}>
                    <a
                        href="#change"
                        onClick={(e) => {
                            e.preventDefault();
                            changePasswordClicked();
                        }}
                    >
                        Change Password
                    </a>
                </div>
            </div>
        </div>
    );

    const logoutPanel = (
        <div className={classes(modalStyles.row, styles.passwordRow)}>
            <div className={modalStyles.label}></div>
            <div className={modalStyles.content}>
                <div className={styles.password}></div>
                <div className={classes(modalStyles.last, styles.pswButton)}>
                    <LogOut style={{ fontWeight: 'bold' }} />
                </div>
            </div>
        </div>
    );

    return (
        <div>
            {!!from && from === 'qrCode' ? (
                <>
                    <div className={modalStyles.header}>
                        <h3>QR Code</h3>
                    </div>
                    <CustomerModal>QR Code Activated</CustomerModal>
                </>
            ) : (
                ''
            )}
            <div className={classes(modalStyles.header, modalStyles.center)}>
                <h3>Account of Death</h3>
            </div>
            <div
                style={{ fontSize: '16px', marginTop: '20px' }}
                className={classes(modalStyles.header, modalStyles.center)}
            >
                Welcome to Account of Death!
            </div>
            <div
                style={{
                    fontSize: '16px',
                    marginTop: '20px',
                    marginBottom: '40px',
                }}
                className={classes(modalStyles.header, modalStyles.center)}
            >
                From here you can access your Kingdom Death: Simulator
                purchases, campaigns and if <br></br> granted access, use the
                beta Dark Treasury to manage your Kickstarter rewards and
                preorders.
            </div>
            <CustomerModal>
                <div className={styles.shopAccountStyles}>
                    <p>Looking for your Kingdom Death Shop Account?</p>
                    <a
                        style={{ fontWeight: 'bold' }}
                        href="https://shop.kingdomdeath.com/account"
                    >
                        Login Here
                    </a>
                </div>
            </CustomerModal>
            <CustomerModal>
                <div className={modalStyles.rowContainer}>
                    <UserTagPanel
                        userTag={userTag}
                        updateUserTag={async (tag) => {
                            uiService.showLoading();
                            let response: Nullable<string> = null;
                            if (!!userTag) {
                                response = await socialService.updateUserTag(
                                    iss,
                                    tag
                                );
                            } else {
                                response = await socialService.creatUserTag(
                                    iss,
                                    tag
                                );
                            }

                            uiService.hideLoading();

                            if (response == null) {
                                uiService.showSuccessNotification(
                                    'Successfuly updated the user tag!'
                                );
                                await getUserTag(iss);
                            } else {
                                uiService.showErrorNotification(response);
                            }
                        }}
                    />
                    <EmailPanel iss={iss} changeEmail={changeEmail}/>
                    {passwordPanel}
                    {logoutPanel}
                </div>
            </CustomerModal>

            <AccountLinking />

            <AdminOnly>
                <CustomerModal header="Deploy Time">{data.date}</CustomerModal>
            </AdminOnly>

            <DownloadLauncherButton />
        </div>
    );
}

const UserTagPanel = ({
    userTag,
    updateUserTag,
}: {
    userTag: Nullable<string>;
    updateUserTag: (newTag: string) => void;
}) => {
    const [isEditingTag, setEditingTag] = useState<boolean>(false);
    const userTagInputRef = useRef<HTMLInputElement | null>(null);
    const [tagToUpdate, setTagToUpdate] = useState<Nullable<string>>(null);
    const [lastError, setLastError] = useState<Nullable<string>>();
    const socialService = useInjection<ISocialService>('ISocialService');
    const uiService = useInjection<IUIService>('IUIService');

    function CopyBlock({ userTag }: { userTag: Nullable<string> }) {
        if (userTag === null) {
            return <></>;
        }
        if (userTag.length <= 0) {
            return <></>;
        }

        return (
            <a
                href="#copy-tag"
                style={{
                    height: '20px',
                    padding: '0px'
                }}
                onClick={async (e) => {
                    e.preventDefault();
                    if (!!userTag) {
                        await navigator.clipboard.writeText(userTag);
                        uiService.showSuccessNotification(
                            'Copied gamer tag to clipboard!'
                        );
                    } else {
                        uiService.showErrorNotification(
                            'Failed to copy the gamer tag!'
                        );
                    }
                }}
            >
                <span
                    className={classes(
                        'material-symbols-outlined',
                        styles.icon,
                        styles.copy
                    )}
                >
                    assignment
                </span>
            </a>
        );
    }

    function TagView({
        tag,
        displayName,
    }: {
        tag: string;
        displayName: Nullable<string>;
    }) {
        if (!displayName) {
            return (
                <>
                    {tag} <CopyBlock userTag={tag} />
                </>
            );
        }

        return (
            <>
                <p style={{ height: '20px', margin: '0px' }}>
                    {displayName}
                    <span style={{ fontWeight: 'normal', fontStyle: 'italic' }}>
                        {` (${tag})`}
                    </span>
                </p>
                <CopyBlock userTag={tag} />
            </>
        );
    }

    useEffect(() => {
        setLastError(null);
        setTagToUpdate(null);
        setEditingTag(false);
    }, [userTag]);

    const changeUserTagClicked = () => {
        const error = socialService.validateTag(tagToUpdate ?? '');
        if (!!error) {
            setLastError(error);
        } else {
            updateUserTag(tagToUpdate!);
        }
    };

    const cancelUserTagEdit = ()=>{
        setLastError(null);
        setTagToUpdate(null);
        setEditingTag(false);
    }

    return (
        <div className={classes(modalStyles.row, styles.accountRow)}>
            <div className={modalStyles.label}>Gamer Tag</div>
            {!isEditingTag && (
                <div className={modalStyles.content}>
                    <div className={styles.tagView}>
                        {!!userTag ? (
                            <TagView
                                tag={userTag}
                                displayName={socialService.formatForDisplay(
                                    userTag
                                )}
                            />
                        ) : (
                            'No Tag Assigned'
                        )}
                    </div>
                    <div
                        className={classes(modalStyles.last, styles.pswButton)}
                    >
                        <a
                            href="#change-tag"
                            onClick={(e) => {
                                e.preventDefault();
                                setEditingTag(true);
                                setTagToUpdate(
                                    !!userTag
                                        ? socialService.formatForEdit(userTag)
                                        : ''
                                );
                                const interval = window.setInterval(() => {
                                    if (!!userTagInputRef.current) {
                                        userTagInputRef.current.select();
                                        window.clearInterval(interval);
                                    }
                                }, 100);
                            }}
                        >
                            {!!userTag
                                ? 'Change Gamer Tag'
                                : 'Create Gamer Tag'}
                        </a>
                    </div>
                </div>
            )}

            {tagToUpdate !== null && (
                <div className={modalStyles.content}>
                    <div className={styles.email}>
                        <input
                            ref={userTagInputRef}
                            type="text"
                            value={tagToUpdate}
                            onChange={(e) => {
                                e.preventDefault();
                                setTagToUpdate(e.target.value);
                            }}
                            onKeyDown={e=>{
                                if(e.key === 'Escape'){
                                    e.preventDefault()
                                    cancelUserTagEdit();
                                }
                            }}
                        />
                        {lastError && (
                            <div className={styles.error}>{lastError}</div>
                        )}
                    </div>
                    <div
                        className={classes(modalStyles.last, styles.pswButton)}
                    >
                         <a
                            href="#cancelEdit"
                            onClick={(e) => {
                                e.preventDefault();
                                cancelUserTagEdit();
                            }}
                        >
                            Cancel
                        </a>
                        <a
                            href="#change-email"
                            onClick={(e) => {
                                e.preventDefault();
                                changeUserTagClicked();
                            }}
                        >
                            Submit Tag
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

const EmailPanel = ({iss, changeEmail} : {iss:string, changeEmail : (email:string)=>void}) => {
    const [changingEmail, setChangingEmail] = useState('');

    const [editingEmail, setEditingEmail] = useState(false);

    const emailTextBoxRef = useRef<HTMLInputElement | null>(null);

    const uiService = useInjection<IUIService>('IUIService')

    const cancelEmailEdit = ()=>{
        setEditingEmail(false);
        setChangingEmail('');
    }

    async function changeEmailClicked() {
        setEditingEmail(false);
        const response = await uiService.showConfirmationDialog(
            `Are you sure you want to change your email to: ${changingEmail}`
        );

        if (!response) {
            return;
        }

        changeEmail(changingEmail);
    }

    return (
            <div className={classes(modalStyles.row, styles.accountRow)}>
                <div className={modalStyles.label}>Email</div>
                {!editingEmail && (
                    <div className={modalStyles.content}>
                        <div className={styles.email}>{iss}</div>
                        <div
                            className={classes(modalStyles.last, styles.pswButton)}
                        >
                            <a
                                href="#change-email"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setEditingEmail(true);
                                    setChangingEmail(iss);
                                    const interval = window.setInterval(() => {
                                        if (!!emailTextBoxRef.current) {
                                            emailTextBoxRef.current.select();
                                            window.clearInterval(interval);
                                        }
                                    }, 100);
                                }}
                            >
                                Change Email
                            </a>
                        </div>
                    </div>
                )}
    
                {!!editingEmail && (
                    <div className={modalStyles.content}>
                        <div className={styles.email}>
                            <input
                                ref={emailTextBoxRef}
                                type="text"
                                value={changingEmail}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setChangingEmail(e.target.value);
                                }}
                                onKeyDown={e=>{
                                    if(e.key === 'Escape'){
                                        e.preventDefault();
                                        cancelEmailEdit();
                                    }
                                }}
                            />
                        </div>
                        <div
                            className={classes(modalStyles.last, styles.pswButton)}
                        >
                             <a
                            href="#cancelEdit"
                            onClick={(e) => {
                                e.preventDefault();
                                cancelEmailEdit();
                            }}
                        >
                            Cancel
                        </a>
                            <a
                                href="#change-email"
                                onClick={(e) => {
                                    e.preventDefault();
                                    changeEmailClicked();
                                }}
                            >
                                Submit Email
                            </a>
                        </div>
                    </div>
                )}
            </div>
    )
}
